<template>
  <div
    v-if="articlesToShow && articlesToShow.length > 0"
    :class="{ '-mt-8 md:-mt-24': partOfTheme }"
  >
    <template v-if="placement === 'side' && label">
      <CommonListLabel
        v-if="!/podcast/.test(type)"
        :label="label"
        :link="link"
        :link-label="linkLabel"
      />
      <CommonListLabel
        v-if="/podcast/.test(type)"
        :icon="PodcastIcon"
        :label="label"
        :link="link"
        :link-label="linkLabel"
      />
      <CommonDivider />
    </template>
    <template v-if="slider">
      <LayoutSwipeList>
        <SwiperSlide v-for="(article, index) in articlesToShow" :key="index">
          <ArticleTeaser
            :index="index"
            :sameSize="true"
            :article="article"
            :slider="true"
            :rss="rss"
            :lazy="lazy"
          />
        </SwiperSlide>
      </LayoutSwipeList>
    </template>
    <template v-else>
      <template v-for="(article, index) in articlesToShow">
        <ArticleTeaser
          v-if="
            !article.FrontpageTheme &&
            !article.FeaturedArticle &&
            !article.SponsoredArticle
          "
          :key="'article' + index"
          :article="article"
          :index="resetIndex ? index : index + offset"
          :small="placement === 'side' ? true : false"
          :same-size="sameSize"
          :bullet="themeList && index !== 0"
          :paper="paper"
          :rss="rss"
          :always-image="alwaysImage"
          :always-image-small="alwaysImageSmall"
          :always-teaser="alwaysTeaser"
          :lazy="lazy"
          :preload="
            index === 0 && preloadFirstImage ? preloadFirstImage : false
          "
        />
        <ArticleTeaser
          v-if="article.FeaturedArticle"
          :key="'article' + index"
          :article="article.FeaturedArticle.Article"
          :index="resetIndex ? index : index + offset"
          :small="placement === 'side' ? true : false"
          :featured="{
            title: article.FeaturedArticle.Title,
            description: article.FeaturedArticle.Description,
          }"
          :rss="rss"
          :lazy="lazy"
        />

        <ArticleTeaser
          v-if="
            article.FrontpageTheme && article.FrontpageTheme.Articles.length > 0
          "
          :key="'article' + index"
          :article="article.FrontpageTheme.Articles[0]"
          :index="resetIndex ? index : index + offset"
          :small="placement === 'side' ? true : false"
          :theme="{
            Title: article.FrontpageTheme.Title,
            Description: article.FrontpageTheme.Description,
            Articles: article.FrontpageTheme.Articles,
            ThemeId: article.FrontpageTheme.ThemeId,
            ThemePriority: article.FrontpageTheme.ThemePriority,
            ThemeUrl: article.FrontpageTheme.ThemeUrl,
            ThemeUrlTitle: article.FrontpageTheme.ThemeUrlTitle,
          }"
          :rss="rss"
          :lazy="lazy"
          :paper="paper"
        />
        <CommonDivider
          v-if="
            articlesToShow.length > 1 &&
            !article.FeaturedArticle &&
            !article.FrontpageTheme &&
            (articlesToShow[index + 1]
              ? !articlesToShow[index + 1].FeaturedArticle
              : true) &&
            (articlesToShow[index + 1]
              ? !articlesToShow[index + 1].FrontpageTheme
              : true)
          "
          :key="'divider' + index"
          :class="
            placement === 'side' || (themeList && index !== 0)
              ? ''
              : themeList && index === 0
              ? 'mt-3 md:mt-5'
              : 'my-3 md:my-5'
          "
        />
      </template>
      <div v-if="pending" class="flex justify-center">
        <CommonLoading />
      </div>
      <div v-else-if="showMore" class="flex justify-center">
        <CommonButton @click="loadMore">
          {{ $t('ShowMore') }}
        </CommonButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'
import { SwiperSlide } from 'swiper/vue'
import PodcastIcon from '~/assets/icons/podcast.svg?url'

type ArticleType =
  | 'instream'
  | 'news'
  | 'writer'
  | 'person'
  | 'column'
  | 'subject'
  | 'articletype'
  | 'subject'

const props = withDefaults(
  defineProps<{
    articles?: ContentArticle[]
    count?: number
    offset?: number
    resetIndex?: boolean
    articleType?: number
    subject?: number
    showMore?: boolean
    label?: string
    paper?: number
    placement?: string
    sameSize?: boolean
    alwaysImage?: boolean
    alwaysImageSmall?: boolean
    alwaysTeaser?: boolean
    page?: number
    link?: string
    linkLabel?: string
    slider?: boolean
    themeList?: boolean
    partOfTheme?: boolean
    rss?: boolean
    type?: ArticleType
    primaryOnly?: boolean
    weeklyTheme?: boolean
    /**
     * Fetches the prioritized articles used on the paper's frontpage.
     * Either from Saga or TopStories depending on the site and paper.
     */
    frontend?: boolean
    noFillup?: boolean
    podcastTypes?: string
    lazy?: boolean
    preloadFirstImage?: boolean
  }>(),
  {
    offset: 0,
    resetIndex: false,
    showMore: false,
    placement: 'main',
    sameSize: false,
    alwaysImage: false,
    alwaysImageSmall: false,
    slider: false,
    themeList: false,
    partOfTheme: false,
    rss: false,
    primaryOnly: false,
    weeklyTheme: false,
    lazy: true,
    preloadFirstImage: false,
    frontend: false,
    noFillup: false,
  }
)

const indexStore = useIndexStore()
const route = useRoute()

const currentPage = ref<number | undefined>(props.page)
const currentCount = ref(props.count ?? 0)
const weeklyThemeArticles = ref(indexStore.weeklyTheme?.Articles)

const {
  data: articlesWithType,
  loadMore,
  pending,
} = props.weeklyTheme
  ? {
      data: weeklyThemeArticles,
      loadMore: () => {
        console.warn('Load more is not supported on weekly theme articles')
      },
      pending: false,
    }
  : await useArticles({
      paper: props.paper,
      type: props.type,
      articleTypeId: props.articleType,
      count: props.count,
      subject: props.subject,
      page: currentPage.value,
      offset: props.offset,
      podcastTypes: props.podcastTypes,
      frontend: props.frontend,
      noFillup: props.noFillup,
      primaryOnly: props.primaryOnly,
      rss: props.rss,
      alreadyFetchedArticles: props.articles,
    })

const type = computed(() => {
  if (props.articleType) {
    return getArticleType(props.articleType)
  } else {
    return 'article'
  }
})
const articlesToShow = computed(() => {
  if (
    props.frontend ||
    props.articleType ||
    props.paper ||
    props.rss ||
    props.weeklyTheme ||
    props.primaryOnly ||
    (route.name &&
      (route.name?.toString().includes('writer_new') ||
        route.name?.toString().includes('person') ||
        route.name?.toString().includes('subject')))
  ) {
    return articlesWithType.value ?? undefined
  } else if (props.subject && Array.isArray(articlesWithType.value)) {
    return articlesWithType.value
  } else if (props.articles) {
    if (route.name === 'serie') {
      return props.articles.slice(
        props.offset + 1,
        props.offset + 1 + currentCount.value
      )
    }
    return props.articles.slice(props.offset, props.offset + currentCount.value)
  }
  return articlesWithType.value ?? []
})
</script>
